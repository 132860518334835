import { instanceToPlain, plainToInstance } from 'class-transformer';

import Subtitles from './models/subtitles';
import SubtitlesList from './models/subtitles-list';
import axios from "axios";

export default class ApiClient {
    private readonly base_path: string = `/api/subtitles`;

    public async list(skip: number = 0, limit: number = 10): Promise<SubtitlesList> {
        const response = await axios.get(`${this.base_path}/list`, { params: { skip: skip, limit: limit } });
        return plainToInstance(SubtitlesList, response.data as object);
    }

    public async create(subtitles: Subtitles): Promise<Subtitles> {
        const json = JSON.stringify(subtitles);
        const response = await axios.post(`${this.base_path}/create`, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return plainToInstance(Subtitles, response.data as object);
    }

    public async update(subtitles: Subtitles): Promise<Subtitles> {
        const json = JSON.stringify(subtitles);
        const response = await axios.post(`${this.base_path}/update`, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return plainToInstance(Subtitles, response.data as object);
    }

    public async generateSrt(uid: string): Promise<File> {
        const json = JSON.stringify({ uid: uid });
        const response = await axios.post(`${this.base_path}/generate_srt`, json, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    }

    public async generateVtt(uid: string): Promise<File> {
        const json = JSON.stringify({ uid: uid });
        const response = await axios.post(`${this.base_path}/generate_vtt`, json, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    }

    public async get(uid: string): Promise<Subtitles> {
        const response = await axios.get(`${this.base_path}/${uid}`);
        return plainToInstance(Subtitles, response.data as object);
    }

    public async delete(uid: string): Promise<boolean> {
        const response = await axios.delete(`${this.base_path}/${uid}`);
        return response.status === 200;
    }
}

import { FunctionalComponent, h } from 'preact';
import { Route, Router } from 'preact-router';

import ApiClient from '../api';
import Edit from '../routes/edit';
import Header from './header';
import List from '../routes/list';
import NotFoundPage from '../routes/notfound';
import Redirect from './redirect';

const App: FunctionalComponent = () => {
    const client = new ApiClient();
    return (
        <div id="preact_root">
            <Header />
            <div id="page">
                <Router>
                    <Route path="/list" component={List} api={client} />
                    <Route path="/list/:page" component={List} api={client} />
                    <Route path="/edit/" component={Edit} api={client} />
                    <Route path="/edit/:uid" component={Edit} api={client} />
                    <NotFoundPage default />
                    <Redirect path='/' to='/list' />
                </Router>
            </div>
        </div>
    );
};

export default App;

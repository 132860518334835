import Model from "./model";
import Phrase from './phrase';

export default class Subtitles extends Model {
    readonly libr: string = 'https://libr.start.ru';

    constructor(
        public phrases: Phrase[] = [],
        public title: string = '',
        public readonly uid: string = '',
        public readonly created: Date = new Date(),
        public readonly modified: Date = new Date()) {
        super();
    }

    public get text(): string {
        let phraseText = []
        for (let phrase of this.phrases ?? []) {
            phraseText.push(phrase.text);
        }
        return phraseText.join('\n');
    }

    public get video_url(): string {
        return `${this.libr}/watch/${this.uid}?formats=hls&quality=FHD&platform=start&with_subtitles=false`;
    }

    public get length(): number {
        return this.text.length;
    }
}
